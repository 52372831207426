import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './App.css';

const Pbi = () => {
	
	const [accessToken, setToken] = useState("");
	const [id, setId] = useState("");
	const [embedUrl, setUrl] = useState("");
	
	useEffect(() => {  
		var embedReportId = '7a0cfd77-1557-440a-adf5-32c9555d0f52';
		var groupID = '64c1ad02-dfb4-4665-9941-e33f14d738c7'; 
		var theembedUrl = 'https://app.powerbi.com/reportEmbed?reportId=' + embedReportId + '&groupId=' + groupID;
		setId(embedReportId);		
		setUrl(theembedUrl);

		var raw = JSON.stringify({
			"group_id": groupID,
			"report_id": embedReportId,
			"accesslevel": "view",
			"allowsaveas": "false"
		});	
		
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("x-api-key", "uZtu9iISFC9VpKV3zzvEs1bb6LSAj3wW8EAGk1zI"); 
        var requestOptions = {method: 'POST', headers: myHeaders, body: raw, redirect: 'follow'};
		
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.apiacumen.com/api/pbitoken", requestOptions);
                const json = await response.json();
                console.log(json.body);
                setToken(json.body);
            } catch (error) {
                console.log("error", error);
            }
        };        
        fetchData();
    }, []);

		
	return (
		<div>
			<div className="logo">
				<img src="./a2damm.png" className="logoimg"></img>
			</div>
			<div style={{height:'100vh', width:'100%'}}>		
				<PowerBIEmbed
					embedConfig = {{
					type: 'report',  
					pageName: 'ReportSection',
					id: id, 
					embedUrl: embedUrl,
					accessToken: accessToken,    // Keep as empty string, null or undefined
					tokenType: models.TokenType.Embed,
					settings: {
						filterPaneEnabled: true,
						navContentPaneEnabled: true,
						//background: 1,                  
						layoutType: models.LayoutType.Custom,
						panes: {
							pageNavigation: {visible: true, position: 1}
							}
						}
					}}
					
					cssClassName={"Embed-container"}
				/>
			
			</div>
		</div>
	);
};
export default Pbi;
